import 'normalize.css'
import 'styles/index.scss'

const colorchange = document.querySelector('.color-overlay')

const hueMin = 97
const hueMax = 284
const sat = 97
const light = 27

function lerp(start, end, t) {
  return (1 - t) * start + t * end
}

window.addEventListener('scroll', () => {
  const scrollTop = window.scrollY
  const totalHeight = document.documentElement.scrollHeight - window.innerHeight
  const scrollPercentage = scrollTop / totalHeight

  const hue = lerp(hueMin, hueMax, scrollPercentage)

  const [h, s, l] = [hue, sat, light].map(Math.round)
  colorchange.style.backgroundColor = `hsl(${h}, ${s}%, ${l}%)`
})
